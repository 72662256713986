<script>
    import { mapActions } from "vuex";
    import { setVariable } from "@/utils";
    import TheModalWrap from "@main/packages/TheModalWrap";
    import BaseInput from "@main/packages/BaseInput";

    export default {
        name: "ModalSelectCity",
        props: {
            city: String,
        },
        data() {
            return {
                search: "",
                isPopularList: true,
                foundCitiesList: [],
                popularCitiesList: [
                    { name: "Москва" },
                    { name: "Санкт-Петербург" },
                    { name: "Екатеринбург" },
                    { name: "Новосибирск" },
                    { name: "Воронеж" },
                    { name: "Краснодар" },
                    { name: "Нижний Новгород" },
                    { name: "Ростов-на-Дону" },
                ],
            };
        },
        computed: {
            currentCityList() {
                return this.isPopularList ? this.popularCitiesList : this.foundCitiesList;
            },
        },
        methods: {
            ...mapActions("Root", ["setNewCity"]),
            searchCity() {
                if (this.search.length > 1) {
                    this.isPopularList = false;
                    this.$store.rootApi
                        .getListCity({
                            term: this.search,
                        })
                        .then((response) => {
                            this.foundCitiesList = response;
                        });
                }
                if (this.search.length === 0) {
                    this.isPopularList = true;
                }
            },
            selectCity(cityName) {
                this.setNewCity({ cityName });
                setVariable("city_selected", true);
                this.$popup.close();
            },
            submitHandler() {
                if (this.foundCitiesList.length) {
                    this.setNewCity({ cityName: this.foundCitiesList[0].name });
                    this.$popup.close();
                }
            },
        },
        components: {
            TheModalWrap,
            BaseInput,
        },
    };
</script>

<template>
    <the-modal-wrap title="Ваш город" data-test="fake-table-row">
        <div class="select-city-modal">
            <form
                class="select-city-modal__form city_change_form"
                @submit.prevent="submitHandler"
            >
                <base-input
                    v-model="search"
                    type="text"
                    placeholder="Выбор города"
                    v-focus
                    @keyup="searchCity()"
                ></base-input>
            </form>

            <ul class="select-city-modal__list">
                <li
                    v-for="(cityItem, i) in currentCityList"
                    :key="i"
                    class="select-city-modal__item"
                    :class="{ 'is-active': cityItem.name === city }"
                    @click="selectCity(cityItem.name)"
                >
                    {{ cityItem.name }}
                </li>
            </ul>
        </div>
    </the-modal-wrap>
</template>

<style lang="scss">
    .select-city-modal__list {
        list-style: none;
        margin-top: 24px;
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        min-height: 256px;
        max-height: 464px;
        overflow: auto;
    }

    .select-city-modal__item {
        padding: 8px 14px;
        font-size: 15px;
        font-weight: var(--modal-city-weight-item);
        line-height: 16px;
        color: var(--modal-city-color-item);
        transition: 0.3s ease-in-out all;
        cursor: pointer;
        border-radius: var(--modal-city-radius);

        &:hover {
            color: var(--modal-city-color-item_hover);
        }

        &.is-active {
            background: var(--modal-city-color-item-bg_active);
            color: var(--modal-city-color-item-active);
        }
    }
</style>
