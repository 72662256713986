<script>
    export default {
        name: "TheModalWrap",
        props: {
            title: String,
            subTitle: String,
        },
    };
</script>

<template>
    <div class="base-popup-wrap">
        <div class="base-popup-wrap__title">{{ title }}</div>
        <div v-if="subTitle" class="base-popup-wrap__sub-title">{{ subTitle }}</div>
        <div class="base-popup-wrap__content">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss">
    .base-popup-wrap__title {
        padding-right: 10px;
        font-size: var(--modal-heading-size);
        font-weight: var(--modal-heding-weight);
        margin-bottom: 24px;
    }

    .base-popup-wrap__sub-title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
    }

    .base-popup-wrap__content {
        margin-bottom: 10px;
    }
</style>
