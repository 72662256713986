<script>
    export default {
        name: "BaseInput",
        props: {
            value: {
                type: String,
            },
            placeholder: {
                type: String,
                default: "",
            },
            type: {
                type: String,
                default: "text",
                validator: (value) => {
                    return ["text", "number", "email", "password", "tel"].includes(value);
                },
            },
            variant: {
                type: String,
                default: "primary",
                validator: (value) => {
                    return ["primary", "secondary", "tertiary"].includes(value);
                },
            },
            maxLength: Number,
            errorText: String,
            name: String,
        },
        data() {
            return {
                error:
                    this.name === "name" &&
                    (this.errorText === "Неверный формат поля" ||
                        this.errorText === "Представьтесь")
                        ? "Пожалуйста используйте кириллицу"
                        : this.errorText,
            };
        },
        computed: {
            className() {
                return this.componentClassNames("base-input").filter(Boolean);
            },
        },
        watch: {
            errorText: {
                handler(error) {
                    this.error =
                        this.name === "name" &&
                        (error === "Неверный формат поля" || error === "Представьтесь")
                            ? "Пожалуйста используйте кириллицу"
                            : error;
                },
            },
        },
        methods: {
            componentClassNames(className) {
                return [
                    this.variant ? `${className}--${this.variant}` : "",
                    this.disabled ? `${className}--disabled` : "",
                    this.error ? `${className}--error` : "",
                    String(this.value).length > 0 ? `${className}--active` : "",
                ];
            },
            keyupElement({ key, target }) {
                if (key !== "Enter") {
                    this.error = "";
                    this.$emit("error", this.name);
                }
                this.$emit("keyup", target.value);
            },
        },
    };
</script>

<template>
    <label class="base-input">
        <input
            class="base-input__field"
            :class="className"
            :placeholder="placeholder"
            :type="type"
            :value="value"
            :name="name"
            :maxlength="maxLength"
            @input="$emit('input', $event.target.value)"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
            @keyup="keyupElement"
            @click="$emit('click')"
            @change="$emit('change')"
            @keypress="$emit('keypress')"
        />
        <span v-if="error" class="base-input__error">
            {{ error }}
        </span>
    </label>
</template>

<style lang="scss">
    @import "style";
</style>
